<template>
  <Transition name="opacity">
    <div v-if="type && isContent" class="container advert-constructor__preview col-gap-3">
      <div class="advert-constructor__preview-symbol" />
      <AdvertPreviewVisual
        :type="type"
        :text="advertContent.text"
        :media-items="advertContent.mediaItems"
        :buttons="advertContent.buttons"
        :repost-url="advertContent.repost.url"
      />
      <div class="flex-center-3 self-end">
        <div v-for="symbol in 2" :key="symbol" class="advert-constructor__preview-symbol" />
      </div></div
  ></Transition>
  <div
    v-if="!type || !isContent"
    class="advert-constructor__preview advert-constructor__preview-dummy flex-center flex-col"
  >
    <img src="/assets/img/logo.svg" alt="Логотип" width="259" height="66" />
    Creative studio
  </div>
</template>
<script setup lang="ts">
import { AdvertType } from '~/interfaces/advert-studio';
import { useAdvertContentStore } from '~/store/AdvertContentModule';

/* eslint-disable no-undef */

interface Props {
  type: AdvertType | undefined;
}

const props = defineProps<Props>();

const advertContent = useAdvertContentStore();

const getAreAttachments = () =>
  advertContent.mediaItems.length;

const isContent = computed(() => {
  if (advertContent.advertType === AdvertType.post) {
    return advertContent.text.length || getAreAttachments() || advertContent.buttons.length;
  }
  if (advertContent.advertType === AdvertType.story) {
    return getAreAttachments();
  }
  return advertContent.repost.url;
});
</script>
<style lang="scss">
div.advert-constructor__preview {
  height: fit-content;
  position: sticky;
  top: 15%;
  width: fit-content;
  border: 1px solid rgb(130 219 240 / 37%);

  @media (max-width: $small-width) {
    width: 420px;
  }

  @media (max-width: 440px) {
    width: 98vw;
    min-width: auto;
  }

  &-symbol {
    height: 20px;
    background: #d9eff6;
    border-radius: 20px;

    &:first-child {
      width: 162px;
    }

    &:nth-child(2) {
      width: 80px;
    }

    &:nth-child(3) {
      width: 50px;
    }
  }

  .current-advert-preview {
    width: 335px;

    @media (max-width: 370px) {
      width: auto;
    }

    &.story {
      width: 317px;
      height: 540px;
    }
  }
}

div.advert-constructor__preview-dummy {
  @apply rounded-2xl gap-4 justify-center;
  background: $white;
  min-width: fit-content;
  border-width: 8px;
  color: $main;
  font-size: 16px;
  padding: 0 32px;
  height:clamp(280px,24vw,370px);
}
</style>
