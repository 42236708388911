<template>
  <div class="col-gap-3 advert-attachments">
    <h2>Добавление изображений и видео</h2>
    <div class="flex-col tip">
      <p>
        Максимум {{ uploadAttachmentsLimit }} вложени{{
          (getEnding(uploadAttachmentsLimit) as Ending).neuterEndingWithE
        }}.
      </p>
      <span>Перетаскиванием изображений и видео можно задать нужную последовательность </span>
    </div>
    <ul class="flex-center-3 mb-4 -mt-2">
      <AdvertLabelTemplate v-if="mediaGallery.length === 1" class="attachment-preview">
        <template #preview>
          <AdvertAttachmentHeader
            :video="mediaGallery[0].mimeType.includes('video')"
            @remove-attachment="removeAttachment"
          />
          <img v-if="mediaGallery[0].mimeType.includes('image')" :src="mediaGallery[0].uri" decoding="async" loading="lazy" />
          <UIVideo v-if="mediaGallery[0].mimeType.includes('video')" :controls="false" :uri="mediaGallery[0].uri" />
        </template>
      </AdvertLabelTemplate>
      <VueDraggableNext
        v-if="mediaGallery.length > 1"
        :list="mediaGallery"
        v-bind="{
          animation: 200,
        }"
        class="flex draggable"
        handle=".attachment-preview"
      >
        <AdvertLabelTemplate
          v-for="({ uri, mimeType }, index) in mediaGallery"
          :id="`${index}-media-item`"
          :key="uri"
          class="attachment-preview"
        >
          <template #preview>
            <div>
              <AdvertAttachmentHeader
                :video="mimeType.includes(ATTACHMENT.video)"
                @remove-attachment="removeAttachment({ uri, mimeType } )"
              />
              <img v-if="mimeType.includes(ATTACHMENT.image)" :src="uri" decoding="async" loading="lazy" />
              <UIVideo v-else :controls="false" :uri="uri" />
            </div>
          </template>
        </AdvertLabelTemplate>
        <AdvertAttachmentsUploadFile
          class="upload-file upload-file-to-gallery"
          :upload-files="uploadFiles"
        />
      </VueDraggableNext>
      <AdvertAttachmentsUploadFile v-else class="upload-file" :upload-files="uploadFiles" />
    </ul>
    <ElDialog
      v-model="showUploadErrorModal"
      class="upload-error-modal"
      width="fit-content"
      @close="closeUploadErrorModal"
    >
      <div class="justify-align-center col-gap-3">
        <Icon
          :color="IconColor.RED"
          name="icon-park-outline:error-picture"
          width="40"
          height="40"
        />
        <h3>Упс! Произошла ошибка при отправке вложения</h3>
        <div class="upload-error-modal__reason">
          {{ uploadError }}
        </div>
      </div>
    </ElDialog>
    <AdvertUploadingFilesModal v-model="showLoadingFilesModal" :loading-files="loadingFiles" />
  </div>
</template>
<script setup lang="ts">
import { ElDialog } from 'element-plus';
import { VueDraggableNext } from 'vue-draggable-next';
import { TELEGRAM_STORIES_LIMIT, UPLOAD_ATTACHMENTS_LIMIT, ATTACHMENT } from '~/common/constants';
import { sliceString } from '~/common/functions';
import {
  AdvertType,
  type MediaGalleryItem,
  type UploadFile,
} from '~/interfaces/advert-studio';
import { useAdvertContentStore } from '~/store/AdvertContentModule';
import LoadingFile from '~/bin/advert-studio/LoadingFile';
import { isImage } from '~/bin/advert-studio/helpers';
import ImageAttachment from '~/bin/advert-studio/ImageAttachment';
import VideoAttachment from '~/bin/advert-studio/VideoAttachment';
import { IconColor } from '~/interfaces/publisher';
import { getEnding, type Ending } from '~/bin/publisher';
import AdvertService from '~/services/AdvertService';

/* eslint-disable no-undef */

interface Props {
  type: AdvertType;
}

const props = defineProps<Props>();

const uploadAttachmentsLimit = computed(() => {
  if (props.type === AdvertType.story) return TELEGRAM_STORIES_LIMIT;
  return UPLOAD_ATTACHMENTS_LIMIT;
});

const advertContentStore = useAdvertContentStore();
const { removeMediaItem, addMediaItems } =
  advertContentStore;

const mediaGallery = computed(() => advertContentStore.mediaItems);

const uploadError = ref('');
const showUploadErrorModal = computed(() => !!unref(uploadError));
const loadingFiles = ref<LoadingFile[]>([]);
const loadingFilesMap = computed(
  () => new Map(unref(loadingFiles).map((loadingFile) => [loadingFile.id, loadingFile])),
);

let uploadedFiles: MediaGalleryItem[] = [];
const showLoadingFilesModal = ref(false);

const closeUploadErrorModal = () => {
  uploadError.value = '';
};

const addLoadingFile = (rawFile: File) => {
  const id = Math.ceil(Math.random() * 1e7);

  if (!showLoadingFilesModal.value) showLoadingFilesModal.value = true;

  const loadingFile = new LoadingFile(id, sliceString(rawFile.name, 10), 0);
  loadingFiles.value.push(loadingFile);

  return loadingFile;
};

const addMediaGallery = () => {
  addMediaItems(uploadedFiles);
};

const onEndUploadAttachments = () => {
  if (!uploadedFiles.length) return;
  addMediaGallery();
};

const removeFileFromLoading = (loadingFile: LoadingFile) => {
  const delay = 100;
  setTimeout(() => {
    loadingFiles.value = unref(loadingFiles).filter(({ id }) => id !== loadingFile.id);
    if (!unref(loadingFilesMap).size) {
      onEndUploadAttachments();
      uploadedFiles = [];
      showLoadingFilesModal.value = false;
    }
  }, delay);
};

const uploadWithLoading = (mimeType: string, rawFile: File, loadingFile: LoadingFile) => {
  const attachmentConstructorArguments: [File, LoadingFile, UploadFile] = [
    rawFile,
    loadingFile,
    () =>
      AdvertService.uploadFile({
        loadingFile,
        rawFile,
      }),
  ];
  const currentAttachment = isImage(mimeType)
    ? new ImageAttachment(...attachmentConstructorArguments)
    : new VideoAttachment(...attachmentConstructorArguments);

  const addUploadedFile = (uri: string, mimeType: string) => {
    uploadedFiles.push({
      uri,
      mimeType,
    });
  };

  return new Promise((resolve) => {
    currentAttachment
      .init()
      .then((uri) => addUploadedFile(uri, mimeType))
      .finally(() => {
        removeFileFromLoading(loadingFile);
        resolve(true);
      });
  });
};

const addToUploadingFilesAndUpload = (rawFile: File) => {
  const loadingFile = addLoadingFile(rawFile);
  uploadWithLoading(rawFile.type, rawFile, loadingFile);
};

const uploadFiles = async (rawFiles: File[]) => {
  const { length: havingGalleryItemsLengh } = unref(mediaGallery);
  const havingAttachmentsCount = havingGalleryItemsLengh;
  const limit = unref(uploadAttachmentsLimit);

  const filesOverLimit = rawFiles.length + havingAttachmentsCount > limit;

  if (filesOverLimit) {
    const attachmentsLeft = limit - havingAttachmentsCount;
    rawFiles.slice(0, attachmentsLeft).forEach(addToUploadingFilesAndUpload);
  } else {
    rawFiles.forEach(addToUploadingFilesAndUpload);
  }
};

const removeAttachment = ({ uri, mimeType }: MediaGalleryItem) => {
    removeMediaItem(uri);
};

</script>
<style lang="scss">
.advert-preview-new__attachment::after {
  font-size: 60px;
  width: 48px;
  height: 48px;
  font-weight: 500;
  border-width: 4px;
}

.advert-preview-new {
  > div {
    justify-content: center;
    align-items: center;
    width: $advert-side;
    height: $advert-side;
    border-radius: 2rem;
  }
}

.tip {
  p {
    font-size: 16px;
  }

  p,
  span {
    font-weight: 400;
  }

  span {
    font-size: 12px;
  }

  margin-bottom: 10px;
}

.advert-attachments {
  .draggable {
    flex-wrap: wrap;
    gap: 12px;
    align-items: self-end;
  }

  > ul {
    flex-wrap: wrap;
  }
}

.attachment-preview .advert-preview div.advert-preview__header {
  .trash-icon {
    margin-left: auto;
  }

  img {
    height: 28px;
    width: 20px;
  }
}

.upload-error-modal div.data-modal__modal {
  h3 {
    margin: 0 10px 30px 0;

    + span {
      right: 10px;
    }
  }

  img {
    padding: 6px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .upload-error-modal__reason {
    color: $gray;
  }

  padding-bottom: 24px;
}

.loading-files-modal {
  h3 {
    margin-top: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    .progress-bar {
      width: 280px;
      background: rgba($gray, 0.3);
      border-radius: 16px;
      overflow: hidden;

      &,
      &__done {
        transition: ease-in 150ms;
        height: 4px;
      }

      &__done {
        background: $main;
        width: 0;
      }
    }
  }
}

.attachment-preview {
  position: relative;

  .advert-preview__header {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 89%;
    > div {
      background-color: rgba($gray, 0.2);
    }
  }

  @media (max-width: $small-width) {
    width: fit-content;
  }
}

.flex.draggable:last-child {
  margin-bottom: 33px;
}
</style>
