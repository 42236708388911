<template>
  <div class="col-gap-3 advert-buttons">
    <h2>Добавление кнопки</h2>
    <div v-if="buttons.length" class="tip">
      <p>Перетаскиванием кнопок можно задать нужную последовательность</p>
    </div>

    <div class="advert-buttons__list">
      <VueDraggableNext
        v-if="buttons.length"
        :list="buttons"
        v-bind="{
          animation: 100,
        }"
        class="draggable col-gap-3"
        handle=".post-button"
      >
        <div
          v-for="button in buttons"
          :key="(button.keyId as number)"
          :class="currentButtonKeyId === button.keyId ? 'active' : ''"
          class="flex-center-3 post-button"
          @click="setCurrentButton(button)"
        >
          <span>{{ button.text ? sliceString(button.text) : `Кнопка ${button.keyId}` }}</span>
          <a :href="button.url" target="_blank" @click.stop>{{ sliceString(button.url) }}</a>
          <Icon
            name="fa:trash"
            width="20"
            height="20"
            class="ml-auto"
            :color="IconColor.GRAY"
            @click.stop="onRemoveButton(button.keyId as number)"
          />
        </div>
      </VueDraggableNext>
    </div>

    <div
      :class="currentButtonKeyId ? '' : 'collapsed'"
      class="advert-buttons__new-button-form flex flex-col"
    >
      <UIField
        ref="buttonTextRef"
        :field="{
          inputName: 'button-text',
          inputLabel: 'Название',
          validatingFunction: validateRequiredField,
          placeholder: 'Укажите название кнопки',
        }"
        maxlength="64"
        @input="setButtonText({ keyId: currentButtonKeyId as number, text: $event })"
      />
      <UIField
        ref="buttonUrlRef"
        :field="{
          inputName: 'button-url',
          inputLabel: 'Ссылка',
          validatingFunction: validateUrl,
          placeholder: 'Укажите ссылку формата http(s):// ...',
        }"
        maxlength="64"
        @input="setButtonUrl({ keyId: currentButtonKeyId as number, url: $event })"
      />
    </div>
    <UIButtonSecondary
      :class="!buttons.length ? 'no-buttons' : ''"
      class="justify-align-center advert-buttons__new"
      @click="createButton($event.target)"
    >
      <Icon
        name="iconoir:plus-circle-solid"
        :color="IconColor.INDIGO"
        width="24"
        height="24"
      />Добавить кнопку
    </UIButtonSecondary>
  </div>
</template>
<script setup lang="ts">
import { VueDraggableNext } from 'vue-draggable-next';
import { sliceString, validateUrl } from '~/common/functions';
import { useAdvertContentStore } from '~/store/AdvertContentModule';
import type { TelegramButton } from '~/interfaces/advert-studio';
import useSetState from '~/composables/UI/useSetState';
import { IconColor } from '~/interfaces/publisher';
import type { UIFieldExpose } from '~/interfaces/publisher/UI';
import { validateRequiredField } from '~/helpers/validators/field-validators';

/* eslint-disable no-undef */
const advertContentStore = useAdvertContentStore();
const { addButton, removeButton, setButtonText, setButtonUrl } = advertContentStore;

const buttons = computed(() => advertContentStore.buttons);

const buttonTextRef = ref<UIFieldExpose>();
const buttonUrlRef = ref<UIFieldExpose>();
let lastKeyId = advertContentStore.buttons.length;
const [currentButtonKeyId, setCurrentButtonKeyId] = useSetState<number | null>(null);

const setCurrentButton = ({ text, url, keyId }: TelegramButton) => {
  buttonTextRef.value?.setState(text);
  buttonUrlRef.value?.setState(url);
  setCurrentButtonKeyId(keyId);
};

const resetButtonUrlAndText = () => {
  buttonTextRef.value?.setState('');
  buttonUrlRef.value?.setState('');
};

const createButton = (target: HTMLElement) => {
  lastKeyId += 1;
  addButton({
    keyId: lastKeyId,
    url: '',
    text: '',
  });
  setCurrentButtonKeyId(lastKeyId);
  resetButtonUrlAndText();
  setTimeout(() => target.scrollIntoView({ behavior: 'smooth' }));
};

const onRemoveButton = (keyId: number) => {
  removeButton(keyId);

  if (currentButtonKeyId.value !== keyId) return;

  setCurrentButtonKeyId(null);
  resetButtonUrlAndText();
};

defineExpose({
  getButtons: () => unref(buttons).map((button) => ({ ...button, keyId: undefined })),
  setButtons: (buttons: TelegramButton[]) => {
    advertContentStore.setButtons(
      (buttons ?? []).map((button, index) => ({ ...button, keyId: index + 1 })),
    );
  },
});
</script>
<style lang="scss">
.advert-buttons {
  .tip p {
    margin: 6px 0 0 0;
  }

  &__list {
    margin: 0;
    padding: 0;

    .post-button {
      background: rgba($gray, 0.05);
      border-radius: 8px;
      padding: 16px 20px;
      border: 1px solid transparent;

      &:not(:first-child) {
        margin-top: 10px;
      }

      > span {
        font-weight: 100;
      }

      a {
        color: rgb(122, 211, 250);
        text-decoration: none;
      }

      &.active {
        transition: ease-in 300ms;
        border: 1px solid $main;
        transform: scale(1.01);
      }
    }
  }

  &__new-button-form {
    gap: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    height: 100%;
    transition: all linear 0.3s;

    &.collapsed {
      height: 0;
      margin: 0;
      opacity: 0;
      z-index: -1;
    }
  }

  .advert-buttons__new-button-form.collapsed + .secondary-button {
    margin-top: -24px;
  }

  .secondary-button {
    padding: 20px;
    max-width: 100%;

    &.no-buttons {
      margin-top: -30px;
    }
  }

  .advert-buttons__new {
    @apply gap-3 py-4 rounded-md;
    max-width: 100%;
  }
}
</style>
