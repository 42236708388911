export const groupNames = {
  smileys_people: 'Смайлы & люди',
  animals_nature: 'Животные & природа',
  food_drink: 'Еда & Напитки',
  activities: 'Занятия',
  travel_places: 'Путешествия',
  objects: 'Объекты',
  symbols: 'Символы',
  flags: 'Флаги',
};
