<template>
  <AdvertContentPost
    v-if="type === AdvertType.post"
    ref="content"
    :advert-text-key="advertTextKey"
  />
  <AdvertContentRepost v-else-if="type === AdvertType.repost" ref="content" />
  <AdvertContentStory
    v-else-if="type === AdvertType.story"
    ref="content"
  />
  <div v-else />
</template>
<script setup lang="ts">
import {
  AdvertType,
} from '~/interfaces/advert-studio';
/* eslint-disable no-undef */

interface Props {
  type: AdvertType | undefined;
}

const props = defineProps<Props>();

defineExpose({
});
</script>
