<template>
  <ElTooltip
    v-for="stepButton in stepsButtonsData"
    :key="stepButton.name"
    :content="stepButton.tooltip"
    placement="top"
    effect="light"
  >
    <AdvertButtonsTemplate :class="stepButton.activeClassName" @click="stepButton.handleClick">
      <Icon :name="stepButton.icon" />
    </AdvertButtonsTemplate>
  </ElTooltip>
</template>
<script setup lang="ts">
import { ElTooltip } from 'element-plus';

/* eslint-disable no-undef */

interface Props {
  editor: any | null;
}

const props = defineProps<Props>();
const emits = defineEmits(['changeText']);
/* eslint-disable no-undef */
const stepsButtonsData = reactive([
  {
    name: 'undo',
    tooltip: 'Шаг назад',
    activeClassName: { 'is-active': props.editor.isActive('undo') },
    icon: 'i-ph-arrow-counter-clockwise-bold',
    handleClick: () => {
      props.editor.chain().focus().undo().run();
      emits('changeText');
    },
  },
  {
    name: 'redo',
    tooltip: 'Шаг вперед',
    activeClassName: { 'is-active': props.editor.isActive('redo') },
    icon: 'i-ph-arrow-clockwise-bold',
    handleClick: () => {
      props.editor.chain().focus().redo().run();
      emits('changeText');
    },
  },
]);
</script>
<style>
.i-ph-arrow-counter-clockwise-bold {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 256 256' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M198.7 198.7a99.9 99.9 0 0 1-141.4 0a12 12 0 0 1 0-17a12.2 12.2 0 0 1 17 0a75.9 75.9 0 1 0 0-107.4L60.8 87.7h19a12 12 0 0 1 0 24h-48a12 12 0 0 1-12-12v-48a12 12 0 1 1 24 0v19l13.5-13.4a100 100 0 0 1 141.4 141.4Z'/%3E%3C/svg%3E");
}

.i-ph-arrow-clockwise-bold {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 256 256' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M236.2 51.7v48a12 12 0 0 1-12 12h-48a12 12 0 0 1 0-24h19l-13.5-13.4a75.9 75.9 0 1 0 0 107.4a12.2 12.2 0 0 1 17 0a12 12 0 0 1 0 17a100 100 0 1 1 0-141.4l13.5 13.4v-19a12 12 0 1 1 24 0Z'/%3E%3C/svg%3E");
}
</style>
