import type { SelectionNodeWithOffset } from '~/interfaces/advert-studio';

export default class EmojiInserter {
  notEmptyString = false;

  offset = {
    value: 0,
  };

  selection: SelectionNodeWithOffset | null;

  anchorNode: Node | null;

  emoji: string;

  constructor(emoji: string, selection: SelectionNodeWithOffset | null) {
    this.selection = null;
    this.anchorNode = null;
    this.setSelection(selection);
    this.emoji = emoji.toString();
    this.setOffsetIfTextNode();
  }

  setSelection = (selection: SelectionNodeWithOffset | null) => {
    this.selection = selection;
    if (this.selection) this.anchorNode = this.selection.anchorNode;
  };

  setOffsetIfTextNode = () => {
    if (this.anchorNode?.nodeName === '#text') {
      this.offset.value = this.selection?.anchorOffset ?? 0;
    }
  };

  init = () => {
    if (!this.selection || !this.anchorNode || !EmojiInserter.validateAnchor(this.anchorNode)) {
      return;
    }
    this.insertToSelectedElementTextNode();
    this.spliceEmoji();
    this.increaseOffset();
    this.setNewElementOffset();
  };

  setNewElementOffset = () => {
    try {
      this.trySetNewOffset();
    } catch (error) {
      console.log(error);
      this.insertInLastEmptyTextNode();
    }
  };

  insertInLastEmptyTextNode = () => {
    if (!(this.anchorNode instanceof HTMLElement)) return;
    this.anchorNode.innerHTML = (this.anchorNode?.textContent?.slice(2) ?? '') + this.emoji;
    if (this.selection) window.getSelection()?.setPosition(this.anchorNode, 0);
  };

  trySetNewOffset = () => {
    window.getSelection()?.setPosition(this.anchorNode, this.offset.value);
    this.notEmptyString = true;
  };

  insertToSelectedElementTextNode = () => {
    if (this.anchorNode) {
      window.getSelection()?.setPosition(this.anchorNode.firstChild, this.offset.value);
    }
  };

  spliceEmoji() {
    const emptyLastTextNode =
      !(this.anchorNode instanceof Node) ||
      !this.anchorNode?.textContent ||
      !this.anchorNode.textContent;
    if (emptyLastTextNode) return;
    const newText = this.anchorNode?.textContent?.split('') ?? [];
    newText.splice(this.offset.value, 0, this.emoji);
    (this.anchorNode as Node).textContent = newText.join('');
  }

  increaseOffset() {
    this.offset.value += 2;
  }

  static validateAnchor = (node: Node) => {
    const parents = [];
    const mirror = document.querySelector('.ProseMirror') as HTMLDivElement;
    for (let i = 0, currentNode = node; i < 3; i += 1) {
      parents.push(currentNode.parentElement);
      if (currentNode.parentElement) currentNode = currentNode.parentElement;
    }
    return parents.includes(mirror);
  };
}
