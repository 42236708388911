<template>
  <ElPopover
    placement="bottom"
    title="Введите ссылку"
    :width="230"
    trigger="focus"
    popper-class="link-popover"
    :visible="isEnteringLink"
  >
    <template #reference>
      <div class="flex-center">
        <ElTooltip content="Вставить ссылку" placement="top">
          <AdvertButtonsTemplate
            :class="{ 'is-active': editor.isActive('link') }"
            @click="handleSetLinkClick"
          >
            <Icon name="i-bx-link" />
          </AdvertButtonsTemplate>
        </ElTooltip>
      </div>
    </template>
    <form class="col-gap-3" @submit.prevent="onSubmitLink">
      <UIField ref="linkField" :field="linkFieldSettings" />
      <UIButtonPrimary>ОК</UIButtonPrimary>
    </form>
  </ElPopover>
  <ElTooltip content="Открепить ссылку" placement="top">
    <AdvertButtonsTemplate
      :class="{ 'is-active': editor.isActive('unsetLink') }"
      :disabled="!editor.isActive('link')"
      @click="unsetLink(typedEditor)"
    >
      <Icon name="bx:unlink" :color="IconColor.BLACK" />
    </AdvertButtonsTemplate>
  </ElTooltip>
</template>
<script lang="ts" setup>
import type { Editor } from '@tiptap/vue-3';
import { ElPopover, ElTooltip } from 'element-plus';
import { IconColor } from '~/interfaces/publisher';
import useModal from '~/composables/UI/useModal';
import { validateUrl } from '~/common/functions';
import type { UIFieldExpose } from '~/interfaces/publisher/UI';

/* eslint-disable no-undef */

interface Props {
  editor: any | null;
}
const props = defineProps<Props>();
const emits = defineEmits(['changeText']);

const typedEditor = props.editor as Editor;

const {
  modalClose: popoverClose,
  modalOpen: popoverOpen,
  viewModalRef: isEnteringLink,
} = useModal();

let awaitingResolvePromise: ((link: string) => void) | null = null;

const linkFieldSettings = {
  inputName: 'link',
  validatingFunction: validateUrl,
};

const insertUrl = (): Promise<string> =>
  new Promise((resolve) => {
    awaitingResolvePromise = (link: string) => {
      resolve(unref(link));
    };
  });

const addUrl = () => {
  popoverOpen();

  insertUrl().then((res: string) => {
    popoverClose();

    props.editor.chain().focus().extendMarkRange('link').setLink({ href: res }).run();
    emits('changeText');
  });
};

const handleSetLinkClick = () => {
  if (isEnteringLink.value) {
    popoverClose();
    return;
  }

  addUrl();
};

const unsetLink = (editor: Editor) => {
  editor.chain().focus().unsetLink().run();

  emits('changeText');
};

const linkField = ref<UIFieldExpose>();

const onSubmitLink = () => {
  const linkFieldValue = unref(linkField) as UIFieldExpose;
  isEnteringLink.value = false;
  awaitingResolvePromise?.(linkFieldValue.value);
  linkFieldValue.setState('');
};
</script>
<style lang="scss">
.link-popover {
  min-height: 9rem;

  input {
    border-radius: 6px;
    border: 1px solid rgba($gray,0.3);
  }

  button {
    min-width: fit-content !important;
    min-height: 32px !important;
  }
}
</style>
