import type { UploadFile } from '~/interfaces/advert-studio';
import AbstractAttachment from './AbstractAttachment';
import type LoadingFile from './LoadingFile';
import { INVALID_VIDEO_MESSAGE, SIZE_LIMIT } from '.';

export default class VideoAttachment extends AbstractAttachment<HTMLVideoElement> {
  constructor(rawFile: File, loadingFile: LoadingFile, uploadFile: UploadFile) {
    super(document.createElement('video'), rawFile, loadingFile, uploadFile);
  }

  init = async (): Promise<string> => {
    const url = window.URL || window.webkitURL;
    this.element.src = url.createObjectURL(this.rawFile);

    await this.validate();

    return this.uploadFile();
  };

  validate = () =>
    new Promise((resolve, reject) => {
      const errors = [];

      // const fileIsVideo = this.rawFile.type.match(/^video\/(mp4|mpeg)/); --- toxic rule
      const fileIsVideo = this.rawFile.type.includes('video');

      if (!fileIsVideo) {
        errors.push(INVALID_VIDEO_MESSAGE.type);
      }

      if (this.rawFile.size > SIZE_LIMIT.video) {
        errors.push(INVALID_VIDEO_MESSAGE.size);
      }

      if (errors.length) {
        reject(errors.join(', '));
        return;
      }

      resolve(true);
    });
}
