<template>
  <button class="flex-center">
    <slot />
  </button>
</template>
<style lang="scss" scoped>
button {
  border-radius: 5px;
  width: 32px;
  padding: 3px 7px;
  border: 0.5px solid transparent;
  font-size: 16px;
  background: transparent;

  &:hover {
    transition: ease-in 0.3s;
    border-color: rgba(0, 0, 0, 0.749);
    transform: none;
  }

  &[disabled] {
    opacity: 0.8;
    color: grey;
  }
}
</style>
