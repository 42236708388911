<template>
  <ElTooltip effect="light" content="Спойлер">
    <AdvertButtonsTemplate
      :class="{ 'is-active': editor.isActive('highlight') }"
      class="spoiler-button justify-align-center"
      :disabled="!editor.can().chain().focus().toggleHighlight().run()"
      @click="handleClick"
    >
      𝕊
    </AdvertButtonsTemplate>
  </ElTooltip>
</template>
<script setup lang="ts">
import { ElTooltip } from 'element-plus';
/* eslint-disable no-undef */

interface Props {
  editor: any;
}

const props = defineProps<Props>();
const emits = defineEmits(['changeText']);

const handleClick = () => {
  props.editor.chain().focus().toggleHighlight().run();
  emits('changeText');
};
</script>
<style lang="scss" scoped>
.spoiler-button {
  background: url('public/assets/img/white-glitch.gif');
  background-size: contain;
  background-position: center center;
}
</style>
