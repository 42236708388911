// import { BASE_METHOD, fetchApi } from '~/common/API/baseApi';
// import AuthService from './AuthService';
import type { Advert, RawFileAndLoadingFile } from '~/interfaces/advert-studio';

const runtimeConfig = useRuntimeConfig();
let uploadMediaLink = '/advert/creative/content-media-item/upload';

export default class AdvertService {

    static uploadFile = async ({ rawFile, loadingFile }: RawFileAndLoadingFile) => {
        const data = new FormData();
        data.append('file', rawFile);

//     const { url } = (await fetchApi({
//       endpoint: '/advert/creative/content-media-item/upload',
//       method: BASE_METHOD.POST,
//       body: data,
//       onUploadProgress: (process) => {
//         loadingFile.setLoadingProgress(process.progress || 0);
//       },
//     })) as {
//       url: string;
//     };

//     return url;
//   };

        let response = await useFetch(uploadMediaLink, {
            baseURL: runtimeConfig.public.LIGHT_API_ORIGIN,
            method: 'POST',
            body: data,
            onRequest({ request, options }) {
            // Set the request headers
            // options.headers = options.headers || {}
            // options.headers.authorization = '...'
            },
            onRequestError({ request, options, error }) {
            // Handle the request errors
            },
            onResponse({ request, response, options }) {
            // Process the response data
            // TODO: remove console.log
            console.log(response);
            },
            // onUploadProgress: (process) => {
            //     loadingFile.setLoadingProgress(process.progress || 0);
            // },
            onResponseError({ request, response, options }) {
            // Handle the response errors
            }
        })
        console.log(response.data.value.url)
        return response.data.value.url;
    };      

//   static fetchAdverts = async () => {
//     const { adverts } = await fetchApi<{ adverts: Advert[] }>({
//       endpoint: '/advert/creative/find',
//       params: {
//         userId: AuthService.contextUserId,
//       },
//     });
//     return adverts;
//   };

//   static fetchAdvert = async (id: number | string) =>
//     fetchApi<Advert>({
//       endpoint: '/advert/creative/view',
//       params: {
//         id,
//       },
//     });

//   static updateAdvert = async (advert: Advert) =>
//     fetchApi<Advert>({
//       endpoint: `/advert/creative/update`,
//       method: BASE_METHOD.PUT,
//       params: {
//         id: advert.id,
//       },
//       body: advert,
//     });

//   static createAdvert = async (advert: Advert) =>
//     fetchApi<Advert>({
//       endpoint: `/advert/creative/create`,
//       method: BASE_METHOD.POST,
//       body: advert,
//     });
}
