<template>
  <ElTooltip effect="light" content="Смайл" placement="top">
    <div class="w-8 relative">
      <AdvertButtonsTemplate @click="onClickPanel"> 😀 </AdvertButtonsTemplate>
      <ClientOnly>
        <transition name="vertical-fade">
          <EmojiPicker
            v-show="isPickingEmoji"
            class="emoji-picker"
            :native="true"
            :group-names="groupNames"
            @blur="togglePickingEmoji"
            @select="$emit('selectEmoji', $event.i)"
            @click.stop
          />
        </transition>
      </ClientOnly>
    </div>
  </ElTooltip>
</template>
<script setup lang="ts">
import { ElTooltip } from 'element-plus';
// @ts-ignore
import EmojiPicker from 'vue3-emoji-picker';
import useCallbackOnBlur from '~/composables/UI/useCallbackOnBlur';
import { groupNames } from '~/bin/advert-studio/emoji-picker-config';

/* eslint-disable no-undef */
const emits = defineEmits(['selectEmoji', 'saveSelection']);

const isPickingEmoji = ref(false);

const { addListener: addClickOutsidePickerListener } = useCallbackOnBlur(() => {
  isPickingEmoji.value = false;
});

const controlEmojiPicker = () => {
  if (!isPickingEmoji.value) addClickOutsidePickerListener();
};

const togglePickingEmoji = () => {
  controlEmojiPicker();

  isPickingEmoji.value = !isPickingEmoji.value;
};

const onClickPanel = () => {
  emits('saveSelection', window.getSelection());
  togglePickingEmoji();
};
</script>
<style lang="scss">
@import 'public/assets/css/libs/emoji-picker.css';

.emoji-picker {
  @apply absolute z-20 top-10;

  &:hover {
    opacity: 1;
  }
}

.vertical-fade-enter-active {
  transition: all 0.3s ease-out;
}

.vertical-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  z-index: 0;
}

.vertical-fade-enter-from {
  transform: translateY(10px);
  opacity: 0;
}
.vertical-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.vertical-fade-active {
  position: absolute;
}
</style>
