import { Editor } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Highlight from '@tiptap/extension-highlight';

export { StarterKit, Underline };

export default function useEditor() {
  const editor = ref<any>(null);

  onMounted(() => {
    editor.value = new Editor({
      extensions: [
        StarterKit,
        Underline,
        Highlight.extend({
          renderHTML({ HTMLAttributes }) {
            return ['span', { ...HTMLAttributes, class: 'tg-spoiler' }, 0];
          },
        }),
        Link.configure({
          validate: (href) => /^https?:\/\//.test(href),
          HTMLAttributes: {
            rel: 'noreferrer',
          },
        }),
        Placeholder.configure({
          placeholder: 'Введите текст вашего поста',
        }),
      ],
    });
  });
  onBeforeUnmount(() => {
    if (editor.value) {
      editor.value.destroy();
    }
  });

  return { editor };
}
