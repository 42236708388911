import { INVALID_IMAGE_MESSAGE, SIZE_LIMIT } from '.';
import AbstractAttachment from './AbstractAttachment';
import type { UploadFile } from '~/interfaces/advert-studio';
import type LoadingFile from './LoadingFile';
import { isImage } from './helpers';

export default class ImageAttachment extends AbstractAttachment<HTMLImageElement> {
  constructor(rawFile: File, loadingFile: LoadingFile, uploadFile: UploadFile) {
    const image = new Image();
    super(image, rawFile, loadingFile, uploadFile);
  }

  init = async (): Promise<string> => {
    const url = window.URL || window.webkitURL;
    this.element.src = url.createObjectURL(this.rawFile);

    return new Promise((resolve, reject) => {
      this.element.onload = async () => {
        this.validate().then(
          async () => {
            try {
              const uploadedFileUrl = await this.uploadFile();
              resolve(uploadedFileUrl);
            } catch (error) {
              reject(error);
            }
          },
          (error) => {
            console.log(error);
            reject(error);
          },
        );
      };
    });
  };

  validate = async () =>
    new Promise((resolve, reject) => {
      const errors = [];

      const fileIsImage = isImage(this.rawFile.type);

      if (!fileIsImage) {
        errors.push(INVALID_IMAGE_MESSAGE.type);
      }
      if (this.rawFile.size > SIZE_LIMIT.image) {
        errors.push(INVALID_IMAGE_MESSAGE.size);
      }
      if (errors.length) {
        reject(errors.join(', '));
        return;
      }
      resolve(true);
    });
}
