<template>
  <label for="repost-input">Вставьте ссылку на репост </label>
  <input
    v-model.trim="repostUrl"
    input-id="repost-input"
    placeholder="https://t.me/socialjet/210"
    @input="advertContentModule.setRepostUrl(($event as any).target.value)"
    @paste="advertContentModule.setRepostUrl(($event as any).target.value)"
  />
</template>
<script setup lang="ts">
import { TELEGRAM_TEXT_MAX_LENGTH } from '~/common/constants';
import { useAdvertContentStore } from '~/store/AdvertContentModule';

/* eslint-disable no-undef */
const advertContentModule = useAdvertContentStore();

const repostUrl = ref(advertContentModule.repost.url);

const validate = () => {
  const errors = [];
  const repostUrlValue = unref(repostUrl);
  if (!repostUrlValue.length) {
    errors.push('Отсутствует ссылка на пост');
  }
  if (repostUrlValue.length > TELEGRAM_TEXT_MAX_LENGTH) {
    errors.push(`Максимальное количество символов в ссылке на пост ${TELEGRAM_TEXT_MAX_LENGTH}`);
  }

  try {
    console.log(new URL(repostUrlValue));
    if (!/t.me/.test(repostUrlValue)) throw new Error('Неверная ссылка на пост');
  } catch {
    errors.push('Укажите корректную ссылку на пост');
  }

  return errors;
};

const getRepostAdvertContent = () => ({
  data: {
    contentType: 'repost',
    content: {
      url: unref(repostUrl),
    },
  },
  validate,
});

defineExpose({
  getRepostAdvertContent,
  setRepostUrl: advertContentModule.setRepostUrl,
});
</script>
