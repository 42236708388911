<template>
  <div class="advert-constructor">
    <div class="flex gap-5">
      <div class="advert-constructor__panels">
        <div class="advert-studio-panel container">
          <UIField ref="orderName" :field="orderNameFieldSettings" />
        </div>
        <div class="advert-studio-panel advert-constructor__panels_start-panel container">
          <UIField ref="advertName" :field="advertNameFieldSettings" />
        </div>
        <div class="advert-studio-panel container">
          <AdvertContent ref="content" :type="advertContentStore.advertType" />
        </div>
        <UIButtonPrimary @click="validateAndComposeAdvert">
          Сохранить изменения
        </UIButtonPrimary>
      </div>
      <AdvertConstructorPreview :type="advertContentStore.advertType" />
    </div>
    <!-- <div class="justify-align-center mobile-button">
      <UIButtonPrimary
        class="mx-auto w-full mt-6"
        @click="validateAndComposeAdvert"
        >Продолжить</UIButtonPrimary
      >
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { advertNameFieldSettings, orderNameFieldSettings } from '~/bin/advert-studio';
// import { errorMessage } from '~/common/feedback';
import { type UIFieldExpose } from '~/interfaces/publisher/UI';
// import { useAdvertStore } from '~/store/AdvertModule';
import { useAdvertContentStore } from '~/store/AdvertContentModule';

/* eslint-disable no-undef */

const advertName = ref<UIFieldExpose>();

const orderName = ref<UIFieldExpose>();

// const advertStore = useAdvertStore();
const advertContentStore = useAdvertContentStore();

// const checkErrors = (errors: string[]) => {
//   if (!errors.length) return;

//   const joinedErrors = errors.join('\n');
//   errorMessage(joinedErrors);
//   throw new Error(joinedErrors);
// };

// const validateAdvertName = () => {
//   const errors: string[] = [];
//   if (!unref(advertName)?.valid) errors.push('Укажите название креатива');
//   return errors;
// };

// const validateNames = () => {
//   const errors = validateAdvertName();
//   if (!unref(orderName)?.valid) errors.push('Укажите название заказа');
//   return errors;
// };

// const drawer = true;

// const validateNameFunction = drawer ? validateAdvertName : validateNames;

// const content = ref();

const validateAndComposeAdvert = () => {
  // const contentWithValidator = unref(content)?.getContentWithValidator();
  // if (!contentWithValidator) throw new Error('Не найден контент');
  // const { data, validate } = contentWithValidator;

  // checkErrors(validateNameFunction());
  // checkErrors(validate());

  // const adverts = [
  //   {
  //     ...data,
  //     advertType: advertContentStore.advertType,
  //     name: unref(advertName)?.value as string,
  //     social: 'telegram',
  //   },
  // ];
};
</script>

<style lang="scss">
.advert-constructor {
  margin-bottom: 60px;
  margin-top: 16px;

  &__panels {
    max-width: 720px;

    > .button-primary {
      max-width: 100%;
    }
  }

  &__panels {
    @apply flex flex-col;

    gap: 20px;
    width: 100%;

    &_start-panel {
      @apply flex flex-col gap-5;
    }

    input,
    .el-select,
    .el-select__wrapper {
      width: 100%;
      max-width: 100%;
    }
  }

  @media (max-width: calc($small-width + 650px)) {
    > .mobile-button {
      max-width: min(720px, 98vw);
      margin-left: auto;
      margin-right: auto;

      button {
        max-width: 100%;
      }
    }

    > div {
      flex-wrap: wrap;

      @media (max-width: calc($small-width + 400px)) {
        .advert-constructor__preview {
          margin: auto;
        }
      }
    }
  }

  .advert-preview,
  .attachment-preview .advert-preview img {
    width: $advert-side;
    height: $advert-side;
    border-radius: clamp(4px, 3vw, 24px);
  }

  .advert-preview,
  .attachment-preview .advert-preview img {
    overflow: hidden;
  }

  .attachment-preview .advert-preview {
    padding: 0;
    flex: 1;
  }

  .advert-preview {
    video {
      height: $advert-side;
      width: $advert-side;
      object-fit: cover;
    }
  }
}
</style>
