<template>
  <div class="flex upload">
    <input ref="upload" type="file" multiple @change="onUpload" />

    <AdvertLabelTemplate class="advert-preview-new" @click="clickOnUpload">
      <template #preview>
        <Icon name="iconoir:plus-circle-solid" :color="IconColor.INDIGO" width="40" height="40" />
      </template>
    </AdvertLabelTemplate>
  </div>
</template>
<script setup lang="ts">
import { UPLOAD_ATTACHMENTS_LIMIT } from '~/common/constants';
import { errorMessage } from '~/common/feedback';
import { IconColor } from '~/interfaces/publisher';

interface Props {
  uploadFiles: (files: File[]) => Promise<unknown>;
}

const props = defineProps<Props>();
/* eslint-disable no-undef */
const upload = ref<HTMLInputElement>();

const clickOnUpload = () => {
  upload.value?.click();
};

const onUpload = () => {
  const files: File[] = Array.from(upload.value?.files ?? []);
  if (files.length > UPLOAD_ATTACHMENTS_LIMIT) {
    props.uploadFiles(files.slice(0, UPLOAD_ATTACHMENTS_LIMIT));
    errorMessage(`Максимальное количество вложений - ${UPLOAD_ATTACHMENTS_LIMIT}`);
  } else {
    props.uploadFiles(files);
  }

  (upload.value as HTMLInputElement).value = '';
};
</script>
<style lang="scss">
.upload {
  input {
    visibility: hidden;
    height: 0;
  }

  .advert-preview-new {
    width: fit-content;
  }
}

#__nuxt .app-wrapper div.upload-file {
  max-width: $advert-side;
  cursor: pointer;

  .advert-preview {
    cursor: pointer;
  }

  input {
    height: 0;
    min-height: 0;
  }
}
</style>
