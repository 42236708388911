export default class LoadingFile {
  constructor(readonly id: number, readonly name: string, public progress = 0) {}

  setProgress(value: number) {
    this.progress = value;
  }

  finish() {
    this.progress = 100;
  }

  setLoadingProgress(uploadProgress: number) {
    const leftLoadPart = 100 - this.progress;
    this.progress = +(this.progress + leftLoadPart * uploadProgress).toFixed(1);
  }
}
