<template>
  <div class="col-gap-3">
    <h2>Текст креатива</h2>
    <div class="relative">
      <div v-if="editor" class="editor-panel flex-center">
        <AdvertButtonsFonts :editor="editor" @change-text="setTextAfterConvert" />
        <AdvertButtonsInsertUrl :editor="editor" @change-text="setTextAfterConvert" />
        <AdvertButtonsTelegram :editor="editor" @change-text="setTextAfterConvert" />
        <AdvertButtonsSteps v-if="editor" :editor="editor" @change-text="setTextAfterConvert" />
        <AdvertButtonsInsertSmile
          v-if="editor"
          @select-emoji="selectEmoji"
          @save-selection="saveSelection"
        />
      </div>
      <EditorContent
        :editor="editor"
        class="editor-content"
        @input="setTextAfterConvert"
        @paste="setTextAfterConvert"
        @keydown="setTextAfterConvert"
        @click="focusTextEditor"
      />
      <p v-if="editor" class="character-count" :class="characterCountErrorClassName">
        {{ textLength }}/{{ textSizeLimit }}
        символов
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import throttle from 'lodash/throttle';
import { EditorContent } from '@tiptap/vue-3';
import useEditor from '~/composables/advert-studio/useEditor';
import EmojiInserter from '~/bin/advert-studio/EmojiInserter';
import type { SelectionNodeWithOffset } from '~/interfaces/advert-studio';
import { useAdvertContentStore } from '~/store/AdvertContentModule';

import {
  TELEGRAM_CAPTION_MAX_LENGTH,
  TELEGRAM_TEXT_MAX_LENGTH,
} from '~/common/constants';

/* eslint-disable no-undef */
const advertContentStore = useAdvertContentStore();

const { setText } = advertContentStore;
const text = computed(() => advertContentStore.text);

const { editor } = useEditor();

const characterCountErrorClassName = ref('');
let debounceSetConvertedText: null | (() => void) = null;

let selection: SelectionNodeWithOffset | null = null;
let editorInput: HTMLDivElement | null = null;

const textSizeLimit = computed(() => {
    if (
      advertContentStore.mediaItems.length
    ) {
      console.log('TELEGRAM_CAPTION_MAX_LENGTH selected: ', TELEGRAM_CAPTION_MAX_LENGTH);
      return TELEGRAM_CAPTION_MAX_LENGTH;
    }
    console.log('TELEGRAM_TEXT_MAX_LENGTH selected: ', TELEGRAM_TEXT_MAX_LENGTH);
    return TELEGRAM_TEXT_MAX_LENGTH;
});

const convertHtmlToText = (text: string) =>
  text
    .split('<p>')
    .join('\n')
    .split('<br><br class="ProseMirror-trailingBreak">')
    .join('\n')
    .replace(/<br>/g, '\n')
    .replace('\n', '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/(<\/p>|<br(.*)>|<p(.*)>|target="_blank" rel="noreferrer")/g, '');

const convertTextIntoHtml = (text: string) => {
  const html = text
    .split('\n')
    .map((paragraph) => `<p>${paragraph}</p>`)
    .join('');

  return html;
};

const setTextAfterConvert = () => {
  if (!debounceSetConvertedText) {
    debounceSetConvertedText = throttle(() => {
      setText(convertHtmlToText((editorInput as HTMLDivElement).innerHTML));
    }, 200);
  }
  debounceSetConvertedText();
};
const selectEmoji = (event: string) => {
  const inserter = new EmojiInserter(event, selection);
  inserter.init();
  if (inserter.notEmptyString) (selection as SelectionNodeWithOffset).anchorOffset += 2;
  setText(convertHtmlToText((editorInput as HTMLDivElement).innerHTML));
};

const saveSelection = (selectionNodeWithOffset: SelectionNodeWithOffset) => {
  selection = {
    anchorNode: selectionNodeWithOffset.anchorNode,
    anchorOffset: selectionNodeWithOffset.anchorOffset,
  };
};

const textLength = computed(() =>
  Array.from(convertHtmlToText(unref(text)).replaceAll(/<[^>]*>/g, '')).reduce(
    (acc, char) => (char.length > 1 ? acc + 2 : acc + 1),
    0,
  ),
);

const syncText = () => {
  (editorInput as HTMLDivElement).innerHTML = convertTextIntoHtml(unref(text));
};

onMounted(() => {
  editorInput = editor.value?.options?.element?.children[0] as HTMLDivElement;
  syncText();
});

const getCharacterCountError = () => {
  if (unref(textLength) >= unref(textSizeLimit)) {
    characterCountErrorClassName.value = 'text-danger';
    return;
  }
  characterCountErrorClassName.value = '';
};

watch(textLength, getCharacterCountError);

const focusTextEditor = () => {
  setTimeout(() => (editorInput as HTMLDivElement).focus());
};

onMounted(() => {
  (editorInput as HTMLDivElement).addEventListener('click', focusTextEditor);
});

defineExpose({
  getText: () => convertHtmlToText(unref(text)),
  setText: (text: string) => {
    setText(text);
    syncText();
  },
});
</script>

<style lang="scss">
.character-count.text-danger {
  color: $red;
}

.ProseMirror-focused {
  outline: 0px solid transparent;
}

.ProseMirror {
  line-height: 150%;
  p {
    margin: 0;
    font-size: 14px;
  }
}

p.is-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror a {
  text-decoration: underline;
  color: $main;
  cursor: pointer;
}

.ProseMirror p.editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.editor-panel {
  z-index: 12;
  position: absolute;
  top: 18px;
  left: 24px;
  display: flex;
  flex: 1;
  padding: 0 4px;
  border-radius: 12px;
  background: $white;
  border-width: 0.3px;
  min-height: 20px;
  gap: 6px;
  border: 1px solid rgba($main, 0.3);
  width: fit-content;

  > div {
    gap: 6px;
  }

  span,
  button {
    color: $gray;
  }

  @media (max-width: $small-width) {
    left: 0;
    top: 0;
    flex-wrap: wrap;

    > div {
      width: fit-content;
    }

    + div {
      padding: 72px 6px 6px 6px;
    }
  }
}

.editor-content {
  color: $black;
  font-size: 16px;
  padding: 64px 24px 6px 24px;
  border-radius: 8px;
  overflow: auto;
  width: 100%;
  height: 300px;
  border: 1px solid #d8f2fa;
  background: #fafeff;
  cursor: text;

  .tg-spoiler {
    opacity: 0.5;
    font-weight: 100;
    font-size: 14px;
  }

  p {
    font-weight: 200;
  }

  code {
    font-family: monospace;
  }

  strong {
    font-weight: 600;
  }
}

.character-count {
  margin-top: 8px;
  color: $gray;
  font-size: 12px;
}
</style>
