import type { UploadFile } from '~/interfaces/advert-studio';
import type LoadingFile from './LoadingFile';

export default class AbstractAttachment<T extends HTMLElement & { src: string }> {
  element;

  rawFile;

  loadingFile;

  uploadFile;

  constructor(element: T, rawFile: File, loadingFile: LoadingFile, uploadFile: UploadFile) {
    this.element = element;
    this.rawFile = rawFile;
    this.loadingFile = loadingFile;
    this.uploadFile = () => uploadFile({ rawFile, loadingFile });
  }
}
