<template>
  <div>
    <NuxtLayout class="new-order" name="data">
      <div class="pl-6 pt-2 pr-6">
        <AdvertConstructor />
      </div>
    </NuxtLayout>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    return {}
  }
})
</script>

<style lang="scss">
</style>
