<template>
  <div class="flex">
    <ElTooltip
      v-for="fontButton in fontsButtons"
      :key="fontButton.tooltip"
      effect="light"
      :content="fontButton.tooltip"
      placement="top"
    >
      <AdvertButtonsTemplate
        :disabled="fontButton.disabled"
        :class="fontButton.className"
        @click="fontButton.handleClick"
        ><component :is="getButton(fontButton.name)" />
      </AdvertButtonsTemplate>
    </ElTooltip>
  </div>
</template>
<script setup lang="ts">
import { ElTooltip } from 'element-plus';
import BoldFontButton from './fonts/Bold.vue';
import ItalicFontButton from './fonts/Italic.vue';
import StrikeFontButton from './fonts/Strike.vue';
import UnderlineFontButton from './fonts/Underline.vue';
import MonospaceFontButton from './fonts/Monospace.vue';

/* eslint-disable no-undef */

interface Props {
  editor: any;
}

const props = defineProps<Props>();
const emits = defineEmits(['changeText']);

const getButton = (fontButtonName: string) => {
  switch (fontButtonName) {
    case 'bold':
      return BoldFontButton;
    case 'italic':
      return ItalicFontButton;
    case 'strike':
      return StrikeFontButton;
    case 'underline':
      return UnderlineFontButton;
    default:
      return MonospaceFontButton;
  }
};

const fontsButtons = computed(() => [
  {
    name: 'bold',
    tooltip: 'Жирный',
    className: '',
    activeClassName: { 'is-active': props.editor?.isActive('bold') },
    disabled: !props.editor?.can().chain().focus().toggleBold().run(),
    handleClick: () => {
      if (props.editor) props.editor.chain().focus().toggleBold().run();
      emits('changeText');
    },
  },

  {
    name: 'italic',
    tooltip: 'Курсив',
    className: '',
    activeClassName: { 'is-active': props.editor?.isActive('italic') },
    disabled: !props.editor?.can().chain().focus().toggleItalic().run(),
    handleClick: () => {
      if (props.editor) props.editor.chain().focus().toggleItalic().run();
      emits('changeText');
    },
  },

  {
    name: 'strike',
    tooltip: 'Зачеркнутый',
    className: '',
    activeClassName: {
      'is-active': props.editor?.isActive('strike'),
    },
    html: StrikeFontButton,
    disabled: !props.editor?.can().chain().focus().toggleStrike().run(),
    handleClick: () => {
      props.editor.chain().focus().toggleStrike().run();
      emits('changeText');
    },
  },

  {
    name: 'underline',
    tooltip: 'Подчеркнутый',
    className: '',
    activeClassName: {
      'is-active': props.editor?.isActive('underline'),
    },
    html: UnderlineFontButton,
    disabled: !props.editor?.can().chain().focus().toggleUnderline().run(),
    handleClick: () => {
      if (props.editor) props.editor.chain().focus().toggleUnderline().run();
      emits('changeText');
    },
  },
  {
    name: 'code',
    tooltip: 'Код',
    activeClassName: { 'is-active': props.editor?.isActive('code') },
    disabled: !props.editor?.can().chain().focus().toggleCode().run(),
    handleClick: () => {
      if (props.editor) props.editor.chain().focus().toggleCode().run();
      emits('changeText');
    },
  },
]);
</script>
